<template>

	<div class="w-100 h-100 position-relative overflow-hidden">

		<div v-if="site" class="w-100 h-100 d-flex">

			<div class="d-flex flex-column w-100 h-100">

				<build_nav :site="site" v-model="preview_size" :preview="staging" :wpurl="preview" :show_share="true" />

				<v-tour name="build-tour" :steps="steps"></v-tour>

				<div v-if="!site.settings['is_build_complete']" class="w-100 h-100 position-relative py-4 overflow-auto">

					<div class="container">

						<div class="row justify-content-center">

							<div class="col-12 col-lg-8 col-xl-7">

								<div class="card mb-4 bg-primary text-white border-0 position-relative">

									<div class="card-body pe-4 p-0 d-flex align-items-center justify-content-between">

										<div class="me-2">
											<div class="mx-auto overflow-hidden" style="width: 300px; height: 300px; margin-top: -30px">
												<img src="@/assets/img/pablo.png" class="img-fluid">
											</div>
										</div>

										<div>
											<h2 class="h3">Would you like me to convert your designs into a pixel perfect, WordPress website?</h2>

											<btn_submit 
												label="Yes please" 
												:icon_after="true" 
												:loading="loading" 
												v-on:click="submit()"
											/>
										</div>

									</div>

									<div class="card-body border-top p-3" style="border-color: rgba( 255, 255, 255, 0.2 ) !important;">
										<div>
											<p>Here is what I will attempt to do <a data-bs-toggle="collapse" href="#collapse" role="button" aria-expanded="false" aria-controls="collapse" class="text-white">(Show more)</a>.</p>

											<div id="collapse" class="collapse">
												<p class="d-flex align-items-center mb-2">
													<span class="material-icons-outlined me-2">check</span>
													Setup a new instance of WordPress
												</p>
												<p class="d-flex align-items-center mb-2">
													<span class="material-icons-outlined me-2">check</span>
													Import the pages from your sitemap
												</p>
												<p class="d-flex align-items-center mb-2">
													<span class="material-icons-outlined me-2">check</span>
													Populate each page with layout and content
												</p>
												<p class="d-flex align-items-center mb-2">
													<span class="material-icons-outlined me-2">check</span>
													Applying any styling choices
												</p>
												<p class="d-flex align-items-center">
													<span class="material-icons-outlined me-2">check</span>
													You can change anything you're not happy with
												</p>
											</div>
										</div>
										<div>
											This should take me around <countdown :time="90" :start="loading" />.
										</div>
									</div>

								</div>

							</div>

						</div>

					</div>

				</div>

				<div v-else class="w-100 h-100 position-relative overflow-hidden d-flex">

					<wp_login v-if="site" v-model="site" :workspace="workspace" :redirect="wireframe_preview" v-on:update:submitted="update_submitted" />

					<preview_settings 
						v-if="site"
						:is_show="true"
						v-model="site"
						:workspace="workspace"
						:is_design="true"
						:is_build="true"
						:mode="'prototype'"
						v-on:update:modelValue="update_site"
						:show_tour="true"
						v-on:update:showTour="show_tour"
						v-on:update:previewSize="(e) => { preview_size = e }"
					/>

					<!-- <div class="overflow-hidden flex-grow-1" style="padding-bottom: 50px;">
						<div class="w-100 h-100 border-bottom">
							<iframe name="preview" :src="wireframe_preview" class="h-100 mx-auto d-block" :style="'width:' + iframe_width"></iframe>
						</div>
					</div>	 -->
					<div class="overflow-hidden flex-grow-1 position-relative" style="padding-bottom: 50px;">
						<div class="w-100 h-100 border-bottom">
							<div v-if="!iframe_loaded || !wp_submitted" class="position-absolute top-50 start-50 translate-middle">
								<span class="spinner-border" role="status">
									<span class="visually-hidden">Loading...</span>
								</span>
							</div>

							<iframe 
							v-on:load="load_iframe"
							:src="wireframe_preview"
							name="iframe-preview" 
							class="h-100 mx-auto d-block iframe" 
							:class="[(!iframe_loaded || !wp_submitted ? 'opacity-0' : '')]"
							:style="'width:' + iframe_width"
							></iframe>
						</div>
					</div>				

				</div>

			</div>

		</div>

		<div class="modal fade" id="success" tabindex="-1" aria-labelledby="successLabel" aria-hidden="true">

			<confetti />

			<div class="modal-dialog modal-dialog-centered" style="z-index: 10">
				<div class="modal-content overflow-hidden">
					<div class="modal-body p-0">
						<div class="card mb-0 bg-primary rounded-0 text-white border-0 position-relative">

							<div class="card-body pe-4 p-0 d-flex align-items-center justify-content-start">

								<div class="me-2">
									<div class="mx-auto overflow-hidden" style="width: 250px; height: 250px;">
										<img src="@/assets/img/pablo.png" class="img-fluid">
									</div>
								</div>

								<div>
									<h2 class="h3">All done!</h2>
									<p class="mb-0">Manage your content, add custom functionality and get your site ready to launch.</p>
									
								</div>

							</div>
						</div>
					</div>
					<div class="modal-footer bg-primary" style="border-color: rgba( 255, 255, 255, 0.2 )">
						<button type="button" class="btn btn-secondary" v-on:click="hide_success">Start building</button>
					</div>
				</div>
			</div>
		</div>

	</div>

</template>

<script>

import { mapGetters } from 'vuex';
import btn_submit from '@/components/btn_submit'
import build_nav from '@/components/build_nav'
import preview_settings from '@/components/preview_settings'
import countdown from '@/components/countdown'
import confetti from '@/components/confetti'
import wp_login from '@/components/wp_login'
import site_service from '@/services/site_service'

export default {
	name: 'site.design',

	props: {
		modelValue: [Object],
		workspace: [Object],
	},

	emits: [
		'update:modelValue',
	],

	components: {
		btn_submit,
		build_nav,
		preview_settings,
		countdown,
		confetti,
		wp_login
	},

	data() {
		return {
			loading: false,
			wp_submitted: false,
			iframe_loaded: false,
			site: null,
			tab: null,
			pages: [],
			preview_size: 'desktop',
			status: 0,
			version: 0,
			steps: [
				{
					target: '.v-step-device',
					header: {
						title: 'Preview',
					},
					content: `Toggle your view to preview your site on desktop, tablet and mobile.`,
					params: {
						placement: 'left'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-styles',
					header: {
						title: 'Styles',
					},
					content: `Continue to change site styles the same way you did in design.`,
					params: {
						placement: 'right'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-content',
					header: {
						title: 'Content',
					},
					content: `Manage content for pages, menus, headers and footers.`,
					params: {
						placement: 'bottom'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-extend',
					header: {
						title: 'Extend',
					},
					content: `Add custom functionality, install plugins and manage version control.`,
					params: {
						placement: 'bottom'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-wp',
					header: {
						title: 'Edit in WordPress',
					},
					content: `Take full control by managing your site via the WordPress admin.`,
					params: {
						placement: 'bottom'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-staging',
					header: {
						title: 'Share',
					},
					content: `Preview your staging site and share it with your client.`,
					params: {
						placement: 'bottom'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
			],
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		staging()
		{
			var url = 'https://'

			if ( this.workspace && this.workspace.custom_domain ) {
				url += this.workspace.custom_domain + '/storage/'
			} else if ( this.workspace ) {
				url += this.workspace.domain + '/storage/'
			}

			if ( this.site ) {
				url += this.site.slug
			}

			return url
		},

		preview()
		{
			var url = 'https://'

			if ( this.workspace ) {
				url += this.workspace.domain + '/storage/'
			}

			if ( this.site ) {
				url += this.site.slug
			}

			return url
		},

		wireframe_preview()
		{
			var url = this.preview

			url += '?v=' + this.version

			if ( this.site ) {
				var email = this.auth_user.email

				var key = this.site.key

				url += '&user=' + email + '&key=' + key + '&type=build'
			}

			return url
		},

		iframe_width()
		{
			var width = '100%';

			if ( this.preview_size == 'mobile' ) {
				width = '340px'
			}

			if ( this.preview_size == 'tablet' ) {
				width = '820px'
			}

			return width
		},

	},

	watch: {
		'wireframe_preview': function()
		{
			this.iframe_loaded = false
		},

	},

	mounted()
	{
		this.site = JSON.parse( JSON.stringify( this.modelValue ) )
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		update_site( e )
		{
			this.$emit('update:modelValue', e)
			this.version++
		},

		async submit( override = null )
		{
			this.loading = true 

			this.wp_loading = true

			this.reset_error()

			this.site.question = 'build'

			if ( override ) {
				this.site.question = override
			}

			site_service.update( this.$route.params.workspace_id, this.$route.params.site_id, this.site ).then(( response ) => {

				this.site = JSON.parse( JSON.stringify( response.data ) )

				this.success = 'Updated successfully'

				this.$emit('update:modelValue', this.site)

				window.$( '#success' ).modal( 'show' );
				
				if ( !this.wp_submitted ) {
					this.get_wp_login()
				}

				this.version++

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {

				this.loading = false

			})
		},

		show_tour()
		{
			var $ = window.$

			$( '#success' ).modal( 'hide' );

			this.$tours['build-tour'].start()
		},

		hide_success()
		{
			var $ = window.$

			$( '#success' ).modal( 'hide' );
		},

		handle_next( type, resolve )
		{
			if ( ['previous', 'next'].includes( type ) ) {
				setTimeout( () => {
					resolve();
				}, 50);
			} else {
				resolve();
			}
		},

		update_submitted( e )
		{
			this.wp_submitted = e
		},

		load_iframe()
		{
			this.iframe_loaded = true;
		},
	}
}
</script>

<style>
	iframe {
		transition: all 0.5s ease-in-out;
	}
</style>