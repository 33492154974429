<template>
	
	<span>{{ seconds }} seconds</span>

</template>

<script>
export default {
	name: 'components.btn_router_link',
	props: {
		time: Number,
		start: Boolean
	},
	data() {
		return {
			seconds: this.time
		}
	},
	mounted() {
		this.countdown()
	},
	methods: {
		countdown()
		{
			var interval = setInterval(() => {
				if ( this.start ) {
					if ( this.seconds > 0 ) {
						this.seconds--
					} else {
						clearInterval( interval )
					}
				} else {
					this.seconds = this.time
				}								
			}, 1000)
		}
	}
}
</script>

<style>

</style>
